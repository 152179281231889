import * as XLSX from 'xlsx';
import { formatDateToString } from './FormatDate';
import { GetParticipantResponse } from '@/Services/api/models/participantsModel';
import { ParticipantCategories } from '@/Services/api/models/participantCategoryModel';
import { CekResponse } from '@/Services/api/models/cekModels';
import { ExportRoomData } from '@/Services/api/models/ControleDeAcessoModels';
import { ExportAttractionData } from '@/Services/api/models/attractionsModels';

export const readExcel = async <T>(file: any): Promise<T[]> => {
    const excelData = new Promise((resolve: (value: T[]) => void, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = (e: any) => {
            const bufferArray = e.target.result;
            const wb = XLSX.read(bufferArray, { type: 'buffer' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data: T[] = XLSX.utils.sheet_to_json(ws, {
                raw: false,
                dateNF: 'dd-MM-yyyy HH:mm:ss',
            });
            resolve(data);
        };

        fileReader.onerror = (error) => {
            reject(error);
        };
    });

    return await excelData;
};

export function ExportToExcel(
    eventName: string,
    participants: GetParticipantResponse[],
    participantCategories?: Array<ParticipantCategories>,
    modules?: string[],
    type?: XLSX.BookType,
) {
    const newParticipants: any[] = [];

    participants.forEach((participant) => {
        newParticipants.push({
            nome: participant.name,
            sobrenome: participant.lastName,
            estado: participant.state,
            cidade: participant.city,
            documento: participant.documentNumber,
            empresa: participant.companyName,
            categoria: participantCategories?.find((category) => category.id === participant.categoryId)?.categoryName ?? '',
            cargo: participant.companyPosition,
            numeroCelular: participant.cellPhoneNumber,
            numeroComercial: participant.commercialPhoneNumber,
            email: participant.email,
            checkIn: participant.checkinDate,
            codigoExternoAux: participant.externalCodeAux,
            origemRegistro: participant.linkOrigin ?? participant.registrationOrigin,
            importadoExcel: participant.isImportedFromExcel,
            ativo: participant.active,
            divergencia: participant.hasDivergence,
            id: participant.id,
            idEvento: participant.eventId
        });
        if(modules.includes('cek')) {
            try{
                newParticipants.find((row) => row.documento === participant.documentNumber).cek = participant.cekWithdrawDate
            }
            catch (e: any) {
                console.log(e)
            } 
        }
    });

    var ws = XLSX.utils.json_to_sheet(newParticipants);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Data');

    const currentDate = formatDateToString(new Date().toISOString().split('T')[0]);
    return XLSX.writeFile(wb, `${eventName}-${currentDate}.${type || 'xls'}`);
}


export function ExportRoomToExcel(
    roomName: string,
    participants: ExportRoomData[],
) {
    const newParticipants: any[] = [];

    participants.forEach((participant) => {
        newParticipants.push({
            'ID': participant.id,
            'Nome': participant.name,
            'Sobrenome': participant.lastName,
            'Documento': participant.documentNumber,
            'Check-in no evento': participant.checkInDate,
            'Check-in na Sala': participant.CheckIn
        });
    });

    var ws = XLSX.utils.json_to_sheet(newParticipants);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Data');

    const currentDate = formatDateToString(new Date().toISOString().split('T')[0]);
    return XLSX.writeFile(wb, `${roomName}-${currentDate}.${'xls'}`);
}

export function ExportAttractionToExcel(
    roomName: string,
    participants: ExportAttractionData[],
) {
    const newParticipants: any[] = [];

    participants.forEach((participant) => {
        newParticipants.push({
            'ID': participant.id,
            'Nome': participant.name,
            'Sobrenome': participant.lastName,
            'Telefone': participant.telCelular,
            'E-mail': participant.email,
            'Check-in no evento': participant.checkInDate,
            'Check-in na atração': participant.CheckIn
        });
    });

    var ws = XLSX.utils.json_to_sheet(newParticipants);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Data');

    const currentDate = formatDateToString(new Date().toISOString().split('T')[0]);
    return XLSX.writeFile(wb, `${roomName}-${currentDate}.${'xls'}`);
}