import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { SignIn } from './Pages/SignIn';
import { useToken } from './Components/UseToken';
import { GetEvents } from './Pages/Events/Consultar';
import { AddEvent } from './Pages/Events/Adicionar';
import { GetParticipants } from './Pages/Participants/Consultar';
import { AddUser } from './Pages/Users/Adicionar';
import { GetUsers } from './Pages/Users/Consultar';
import { AddParticipant } from './Pages/Participants/Adicionar';
import { AutoAttendantCheckout } from './Pages/AutoAttendant/CheckIn';
import { RedirectOnLogin } from './Components/CustomRoutes/LoginRedirect';
import { AutoAttendantRoute } from './Components/CustomRoutes/PrivateRoutes/AutoAtendantRoute';
import { ReceptionPrivate } from './Components/CustomRoutes/PrivateRoutes/ReceptionRoute';
import { AdminRoute } from './Components/CustomRoutes/PrivateRoutes/AdminRoute';
import { AutoAttendant } from './Pages/AutoAttendant/Homepage';
import { ConfigureLabel } from './Pages/Events/ConfigureLabel';
import { SignInParticipantInAutoAttendant } from './Pages/AutoAttendant/signInParticipant';
import { EventReports } from './Pages/Events/Relatórios';
import { Instalacao } from './Pages/Instalação';
import { Configurations } from './Pages/Configurations';
import CekDash from './Pages/Modules/Cek/Consultar';
import MalexDash from './Pages/Modules/Malex/Consultar';
import { PartnerAdminRoute } from './Components/CustomRoutes/PrivateRoutes/PartnerAdminRoute';
import ControleDeAtracao from './Pages/Modules/CESS/Consultar';
import Atracoes from './Pages/Modules/CESS/Atracoes';
import AddAtracao from './Pages/Modules/CESS/AddAtracao';
import AttractionCheckIn from './Pages/Modules/CESS/AttractionCheckIn';
import Enquetes from './Pages/Modules/Enquetes/Consultar';
import ResponderEnquete from './Pages/Modules/Enquetes/Responder';
import { AnswerQuestionsInAutoAttendant } from './Pages/AutoAttendant/QuestionsAnswers';
import { CESSRoute } from './Components/CustomRoutes/PrivateRoutes/CESSRoute';
import { CESSReceptionRoute } from './Components/CustomRoutes/PrivateRoutes/CESSReceptionRoute';
import { ConfigureEnquete } from './Pages/Modules/Enquetes/Configuracao';
import AddSala from './Pages/Modules/ControleDeAcesso/AddSala';
import Salas from './Pages/Modules/ControleDeAcesso/Salas';
import ControleDeAcesso from './Pages/Modules/ControleDeAcesso/Consultar';
import RoomCheckIn from './Pages/Modules/ControleDeAcesso/CheckInSala';
import Groups from './Pages/Modules/ControleDeAcesso/Groups';
import AddGrupo from './Pages/Modules/ControleDeAcesso/AddGrupo';
import AccessControlParticipants from './Pages/Modules/ControleDeAcesso/Participants';

export function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<RedirectOnLogin />} />
                <Route path='/login' element={<SignIn />} />

                <Route
                    path='/instalacao'
                    element={
                        <PartnerAdminRoute>
                            <Instalacao />
                        </PartnerAdminRoute>
                    }
                />

                <Route
                    path='/configuracao'
                    element={
                        <AdminRoute>
                            <Configurations />
                        </AdminRoute>
                    }
                />

                <Route
                    path='/eventos/consultar'
                    element={
                        <ReceptionPrivate>
                            <GetEvents />
                        </ReceptionPrivate>
                    }
                />
                <Route
                    path='/eventos/adicionar'
                    element={
                        <AdminRoute>
                            <AddEvent />
                        </AdminRoute>
                    }
                />
                <Route
                    path='/eventos/editar'
                    element={
                        <PartnerAdminRoute>
                            <AddEvent />
                        </PartnerAdminRoute>
                    }
                />
                <Route
                    path='/eventos/configurarEtiqueta/:eventId'
                    element={
                        <PartnerAdminRoute>
                            <ConfigureLabel />
                        </PartnerAdminRoute>
                    }
                />
                <Route
                    path='/eventos/acompanhamento/:eventId'
                    element={
                        <ReceptionPrivate>
                            <EventReports />
                        </ReceptionPrivate>
                    }
                />
                <Route
                    path='/usuarios/consultar'
                    element={
                        <PartnerAdminRoute>
                            <GetUsers />
                        </PartnerAdminRoute>
                    }
                />
                <Route
                    path='/usuarios/adicionar'
                    element={
                        <PartnerAdminRoute>
                            <AddUser />
                        </PartnerAdminRoute>
                    }
                />
                <Route
                    path='/usuarios/editar'
                    element={
                        <PartnerAdminRoute>
                            <AddUser />
                        </PartnerAdminRoute>
                    }
                />
                <Route
                    path='/participantes/consultar/:eventID'
                    element={
                        <ReceptionPrivate>
                            <GetParticipants />
                        </ReceptionPrivate>
                    }
                />
                <Route
                    path='/participantes/adicionar/:eventID'
                    element={
                        <ReceptionPrivate>
                            <AddParticipant />
                        </ReceptionPrivate>
                    }
                />
                <Route
                    path='/participantes/editar/:userID'
                    element={
                        <ReceptionPrivate>
                            <AddParticipant />
                        </ReceptionPrivate>
                    }
                />

                <Route
                    path='/autoattendant/'
                    element={
                        <AutoAttendantRoute>
                            <AutoAttendant />
                        </AutoAttendantRoute>
                    }
                />

                <Route
                    path='/autoattendant/checkin/:language'
                    element={
                        <AutoAttendantRoute>
                            <AutoAttendantCheckout />
                        </AutoAttendantRoute>
                    }
                />
                <Route
                    path='/autoattendant/registrarParticipante/:language'
                    element={
                        <AutoAttendantRoute>
                            <SignInParticipantInAutoAttendant />
                        </AutoAttendantRoute>
                    }
                />
                <Route
                    path='/modulos/cek/consultar/:eventID'
                    element={
                        <ReceptionPrivate>
                            <CekDash/>
                        </ReceptionPrivate>
                    }
                />
                <Route
                    path='/modulos/malex/consultar/:eventID'
                    element={
                        <ReceptionPrivate>
                            <MalexDash/>
                        </ReceptionPrivate>
                    }
                />
                <Route
                    path='/modulos/cess/consultar/:eventID/:attractionID'
                    element={
                        <CESSRoute>
                            <ControleDeAtracao/>
                        </CESSRoute>
                    }
                />
                <Route
                    path='/modulos/cess/atracoes/:eventID'
                    element={
                        <CESSRoute>
                            <Atracoes/>
                        </CESSRoute>
                    }
                />
                <Route
                    path='/modulos/cess/atracoes/'
                    element={
                        <CESSRoute>
                            <Atracoes/>
                        </CESSRoute>
                    }
                />
                <Route
                    path='/modulos/cess/adicionar/:eventID'
                    element={
                        <PartnerAdminRoute>
                            <AddAtracao/>
                        </PartnerAdminRoute>
                    }
                />
                <Route
                    path='/modulos/cess/check-in/:eventID/:attractionID'
                    element={
                        <CESSReceptionRoute>
                            <AttractionCheckIn/>
                        </CESSReceptionRoute>
                    }
                />
                <Route
                    path='/modulos/cess/check-in/'
                    element={
                        <CESSReceptionRoute>
                            <AttractionCheckIn/>
                        </CESSReceptionRoute>
                    }
                />
                <Route
                    path='/modulos/enquetes/consultar/:eventID/'
                    element={
                        <ReceptionPrivate>
                            <Enquetes/>
                        </ReceptionPrivate>
                    }
                />
                <Route
                    path='/modulos/enquetes/responder/:eventID/:participantID'
                    element={
                        <AutoAttendantRoute>
                            <ResponderEnquete/>
                        </AutoAttendantRoute>
                    }
                />
                <Route
                    path='/questoes/:eventID/:participantID'
                    element={
                        <AutoAttendantRoute>
                            <AnswerQuestionsInAutoAttendant/>
                        </AutoAttendantRoute>
                    }
                />
                <Route
                    path='/modulos/enquetes/configuracao/:eventID'
                    element={
                        <AdminRoute>
                            <ConfigureEnquete />
                        </AdminRoute>
                    }
                />
                <Route
                    path='/modulos/controle-de-acesso/adicionar/:eventID'
                    element={
                        <PartnerAdminRoute>
                            <AddSala/>
                        </PartnerAdminRoute>
                    }
                />
                <Route
                    path='/modulos/controle-de-acesso/editar/:eventID/:roomId'
                    element={
                        <PartnerAdminRoute>
                            <AddSala/>
                        </PartnerAdminRoute>
                    }
                />
                <Route
                    path='/modulos/controle-de-acesso/salas/:eventID'
                    element={
                        <ReceptionPrivate>
                            <Salas/>
                        </ReceptionPrivate>
                    }
                />
                <Route
                    path='/modulos/controle-de-acesso/grupos/adicionar/:eventID'
                    element={
                        <PartnerAdminRoute>
                            <AddGrupo/>
                        </PartnerAdminRoute>
                    }
                />
                <Route
                    path='/modulos/controle-de-acesso/grupos/editar/:groupID'
                    element={
                        <PartnerAdminRoute>
                            <AddGrupo/>
                        </PartnerAdminRoute>
                    }
                />
                <Route
                    path='/modulos/controle-de-acesso/grupos/:eventID'
                    element={
                        <AdminRoute>
                            <Groups/>
                        </AdminRoute>
                    }
                />
                <Route
                    path='/modulos/controle-de-acesso/consultar/:eventID/:roomID'
                    element={
                        <ReceptionPrivate>
                            <ControleDeAcesso/>
                        </ReceptionPrivate>
                    }
                />
                <Route
                    path='/modulos/controle-de-acesso/check-in/:eventID/:roomID'
                    element={
                        <ReceptionPrivate>
                            <RoomCheckIn/>
                        </ReceptionPrivate>
                    }
                />
                <Route
                    path='/modulos/controle-de-acesso/participantes/:eventID/'
                    element={
                        <ReceptionPrivate>
                            <AccessControlParticipants/>
                        </ReceptionPrivate>
                    }
                />
            </Routes>
        </BrowserRouter>
    );
}
