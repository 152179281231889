import React from 'react';
import { Spinner } from 'react-activity';
import 'react-activity/dist/Spinner.css';
import { useTheme } from 'styled-components';
import { ModalBlock, ModalBody, ModalContainer} from './styled';
import CloseIcon from '@mui/icons-material/Close';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';

interface ModalLoadingProps {
    isActive: boolean;
    getResult: Function;
    onClose: Function;

}

export default function ModalCapture(props: ModalLoadingProps) {
    let lastQr = ''

    return (
        <>
            {props.isActive && <ModalContainer>
                            <CloseIcon onClick={() => props.onClose()}/>
                            <BarcodeScannerComponent
                                key="environment"
                                onUpdate={(error, result) => {
                                if (!!result) {
                                    const text = result?.getText()
                                    if (text !== lastQr) {
                                        props.getResult(result?.getText())
                                        lastQr = text
                                    }
                                }
                        
                                if (!!error) {
                                    console.info(error);
                                }
                                }}
                            />
                </ModalContainer>
            }
        </>
    );
}
