import styled from 'styled-components';

export const CekDashContainer = styled.div``;

export const WhiteBoxContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.white};

    min-height: 80vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    margin: 20px;
    margin-top: 10px;

    @media screen and (max-width: 705px) {
        flex-direction: column;
        .addField {
            min-width: 50vw;
        }
    }

    .header {
        > button {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 41px;
            height: 22px;
            font-size: ${({ theme }) => theme.fontSize.xs};
        }
    }
    .body {
        display: flex;
        flex-direction: row;
    }

    .tabela {
        overflow: auto;
        padding-bottom: 50px;
        min-height: 70vh;
    }

    th {
        font-weight: 700;
    }

    td,
    th {
        font-size: ${({ theme }) => theme.fontSize.form};
    }

    .dropdown {
        > button {
            display: flex;
            align-items: center;
            justify-content: center;

            color: white;
            height: 22px;
            width: 50px;
            font-size: ${({ theme }) => theme.fontSize.xs};

            :hover {
                color: white;
            }
        }

        .dropdown-item {
            font-size: ${({ theme }) => theme.fontSize.xs};
            color: ${({ theme }) => theme.colors['gray-700']};
        }
    }

    .hiddenInput {
        display: None
    }

`;

export const LeftSideContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 30%;
    @media screen and (max-width: 705px) {
        width: 80vw;
    }
`

export const RightSideContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 60%;
    margin-top: 5vh;
    @media screen and (max-width: 705px) {
        width: 80vw;
    }
`

export const StatusButton = styled.button`
    font-size: ${({ theme }) => theme.fontSize.us};
    color: ${({ theme }) => theme.colors.white};
    font-weight: 700;

    text-transform: uppercase;
    border-radius: 4px;

    border: none;
    padding: 3px 8px;
`;

export const SmallerColumn = styled.td`
    width: 10%;

    .active {
        background-color: ${({ theme }) => theme.colors.steelblue};
    }

    .inative {
        background-color: ${({ theme }) => theme.colors['pink-500']};
    }
`;

export const CapitalizedRow = styled.td`
    text-transform: capitalize;
`;

export const AddContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 90vh;
    justify-content: space-between;
`;

export const FilterContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

export const FilterFields = styled.div`
    display: flex;
    flex-direction: row;
    gap: 15px;
    width: 100%;
`;

export const ChartsContainer = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: center;

    flex-wrap: wrap;
    gap: 25px;
`;
export const Chart = styled.div`
    text-align: center;
`;

export const DynamicMenuContainer = styled.div`
    display: flex;
    margin-top: 5vh;
    margin-bottom: 2vh;
    height: 6vh;
`
export const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

export const Title = styled.div`
    font-weight: bold;
    font-size: ${({ theme }) => theme.fontSize.lg};
    margin-bottom: 4vh;
`;

export const DashedLine = styled.div`
    border-left: 1px dashed black;
`;

export const WithdrawButton = styled.button`
    font-size: ${({ theme }) => theme.fontSize.us};
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors['pink-500']};
    font-weight: 700;

    text-transform: uppercase;
    border-radius: 4px;

    border: none;
    padding: 3px 8px;
`;

export const HeaderRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    margin-top: 10px;
`

export const WarnText = styled.p`
    font-weight: bolder;
    font-size: ${({ theme }) => theme.fontSize.sm};
    color: ${({ theme }) => theme.colors['red-500']};
    margin: 0;
    padding-top: 5px;
`;

export const CameraButton = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    border: none;
    padding: 8px 12px;

    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.steelblue};
    color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => theme.fontSize.sm};
    cursor: pointer;
    width: 100%;
    align-items: center;
    justify-content: center;
`

export const ImagePreview = styled.img`
    max-width: 100%;
    max-height: 200px;
`