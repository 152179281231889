import styled from 'styled-components';

export const ModalContainer = styled.div`
    padding: 2vh;
    justify-content: center;
    display: flex;
    flex-direction: column
`

export const Text = styled.p`
    font-size: ${({ theme }) => theme.fontSize.form};
`