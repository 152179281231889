import { Navigate, useLocation } from 'react-router-dom';
import { useToken } from '../UseToken';
import ApiService from '@/Services/api/ApiService';
import { useState } from 'react';

export const RedirectOnLogin = () => {
    const { token } = useToken();

    if (!token) {
        return <Navigate to='/login' />;
    }

    if (token.user.userGroup === 'RECEPTION') {
        console.log(token.user)
        return <Navigate to={`/eventos/consultar`} />;
    }

    if (token.user.userGroup === 'ADMIN') {
        return <Navigate to='/eventos/consultar' />;
    }

    if (token.user.userGroup === 'PARTNER_ADMIN') {
        return <Navigate to='/eventos/consultar' />;
    }

    if (token.user.userGroup === 'SELF_SERVICE') {
        return <Navigate to='/autoattendant/' />;
    }

    if(token.user.userGroup === 'ATTRACTION') {
        return <Navigate to={`/modulos/cess/atracoes/`} />
    }

    if(token.user.userGroup === 'ATTRACTION_RECEPTION') {
        return <Navigate to={`/modulos/cess/check-in/`} />
    }

    return <Navigate to='/login' />;
};
