import { Background } from '@/Components/Background';
import { Header } from '@/Components/Header';
import ModalLoading from '@/Components/ModalLoading';
import ApiService from '@/Services/api/ApiService';
import { useEffect, useState } from 'react';
import {
    CapitalizedRow,
    GetEventosContainer,
    SmallerColumn,
    StatusButton,
    WhiteBoxContainer,
} from './styled';
import { ActionButtonOptions, TableHeader } from '@/Models/CrudTableModel';
import { EventResponse, IParticipantSearchBy } from '@/Services/api/models/eventModel';
import { Table } from 'react-bootstrap';
import { DropdownButton } from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { formatDateToString } from '@/util/FormatDate';
import { FormButton } from '@/Components/FormButton';
import { toast } from 'react-toastify';
import { useToken } from '@/Components/UseToken';

export function GetEvents() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [events, setEvents] = useState<EventResponse[]>();
    const { token, setAdminEvent } = useToken();
    const [isUserAdmin, setIsUserAdmin] = useState<boolean>(false)

    const navigate = useNavigate();

    const handleGetSingleEvent = async () => {
        try {
            const userInfo = await ApiService.getUserByID(token?.user.id)
            setIsLoading(true);
            const userEvents = userInfo?.events || [0]
            const events = await Promise.all(
                userEvents.map(async (event) => {
                  const response = await ApiService.getEventById(event);
                  return response;
                })
            );
            setEvents(events);
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleGetAllEvents = async () => {
        try {
            setIsLoading(true);
            const response = await ApiService.getAllEventos();
            const oderderdEvents = response.sort(function (a,b) { 
                if(a.initialDate > b.initialDate) {
                    return 1
                }
                if(a.initialDate < b.initialDate) {
                    return -1
                }
                return 0
            })
            setEvents(oderderdEvents);
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleGetUserByID = async () => {
        try {
            setIsLoading(true);
            const response = await ApiService.getUserByID(token?.user?.id);
            if(response.userGroup === 'ADMIN') {
                setIsUserAdmin(true)
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setIsLoading(false);
        }
    }

    const handleGetParticipants = (id: number) => {
        navigate('/participantes/consultar/' + id.toString());
    };

    const FormatedColumns: TableHeader<EventResponse>[] = [
        {
            title: '',
            key: 'empty',
        },
        {
            title: 'Nome',
            key: 'description',
        },
        {
            title: 'Dt. Inicio',
            key: 'initialDate',
        },
        {
            title: 'Dt. Final',
            key: 'finalDate',
        },
        {
            title: 'Local',
            key: 'place',
        },
        {
            title: 'Nº O.S',
            key: 'serviceOrder',
        },
        {
            title: 'Tipo de Pesquisa',
            key: 'participantSearchBy',
        },
        {
            title: 'Inativo',
            key: 'active',
        },
    ];

    const handleEditEvent = (id: number) => {
        navigate('/eventos/editar', { state: id });
    };


    const actionOptions: ActionButtonOptions<EventResponse>[] = [
        {
            title: 'Editar',
            onClick: (event) => handleEditEvent(event.id),
        },
        {
            title: 'Ver participantes',
            onClick: (event) => handleGetParticipants(event.id),
        },
        {
            title: 'Relatório de acompanhamento',
            onClick: (event) => navigate(`/eventos/acompanhamento/${event.id}`),
        },
        {
            title: 'Configurar Etiqueta',
            onClick: (event) => navigate(`/eventos/configurarEtiqueta/${event.id}`),
        },
        {
            title: 'Ver Usuários',
            onClick: (event) => navigate(`/usuarios/consultar`,{state: {event: event.id}}),
        },
    ];

    if (token?.user.userGroup === 'ADMIN' || token?.user.userGroup === 'PARTNER_ADMIN') {
        actionOptions.push({
            title: 'Simular Auto-Atendimento',
            onClick: (event) => {
                setAdminEvent(event.id);
                navigate('/autoattendant');
            },
        });
    }

    useEffect(() => {
        handleGetUserByID()
        if (token?.user.userGroup === 'RECEPTION' || token?.user.userGroup === 'PARTNER_ADMIN') {
            handleGetSingleEvent();
        } else {
            handleGetAllEvents();
        }
    }, []);

    return (
        <Background>
            <ModalLoading isActive={isLoading} />
            <GetEventosContainer>
                <Header pageTitle='Consulta de Eventos' />
                <WhiteBoxContainer>
                    {isUserAdmin &&
                        <div className='header'>
                            <FormButton onClick={() => navigate('/eventos/adicionar')}>
                                {' '}
                                Novo{' '}
                            </FormButton>
                        </div>
                    }
                    <div className='body'></div>
                    <div className='tabela'>
                        {events && (
                            <Table hover>
                                <thead>
                                    <tr>
                                        {FormatedColumns.map((column) => (
                                            <th key={column.key}> {column.title} </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {events &&
                                        events.map((eventMapped) => {
                                            return (
                                                <tr key={eventMapped.id}>
                                                    <SmallerColumn>
                                                        {' '}
                                                        <DropdownButton
                                                            variant='warning'
                                                            id={`dropdown-variants-Warning`}
                                                            title={'Ação'}
                                                            color={'white'}
                                                            className='teste'
                                                            size='sm'
                                                        >
                                                            {actionOptions.map((action) => {
                                                                return (
                                                                    <Dropdown.Item
                                                                        onClick={() =>
                                                                            action.onClick(
                                                                                eventMapped,
                                                                            )
                                                                        }
                                                                    >
                                                                        {' '}
                                                                        {action.title}{' '}
                                                                    </Dropdown.Item>
                                                                );
                                                            })}
                                                        </DropdownButton>{' '}
                                                    </SmallerColumn>
                                                    <td> {eventMapped.description} </td>
                                                    <td>
                                                        {' '}
                                                        {eventMapped.initialDate &&
                                                            formatDateToString(
                                                                eventMapped.initialDate,
                                                            )}{' '}
                                                    </td>
                                                    <td>
                                                        {' '}
                                                        {eventMapped.finalDate &&
                                                            formatDateToString(
                                                                eventMapped.finalDate,
                                                            )}{' '}
                                                    </td>
                                                    <td>
                                                        {' '}
                                                        {eventMapped.place &&
                                                            eventMapped.place.slice(0, 20)}{' '}
                                                    </td>
                                                    <td> {eventMapped.serviceOrder} </td>
                                                    <td>
                                                        {' '}
                                                        <CapitalizedRow>
                                                            {' '}
                                                            {IParticipantSearchBy[
                                                                eventMapped.participantSearchBy
                                                            ].toLocaleLowerCase()}{' '}
                                                        </CapitalizedRow>
                                                    </td>
                                                    <SmallerColumn>
                                                        <StatusButton
                                                            className={
                                                                eventMapped.active
                                                                    ? 'active'
                                                                    : 'inative'
                                                            }
                                                        >
                                                            {eventMapped.active
                                                                ? 'Ativo'
                                                                : 'Inativo'}
                                                        </StatusButton>
                                                    </SmallerColumn>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </Table>
                        )}
                    </div>
                </WhiteBoxContainer>
            </GetEventosContainer>
        </Background>
    );
}
