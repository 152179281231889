import { GetParticipantResponse } from '@/Services/api/models/participantsModel';
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { ModalContainer, Text } from './styled';
import { TButton } from '../TButton';

interface Props {
    show: boolean
    onButtonClick: () => void;
    participant: GetParticipantResponse;
}

export default function ModalParticipantInfo({
    show,
    onButtonClick,
    participant
}:Props) {
    const [additionalInfo, setAdditionalInfo] = useState<any[]>([])

    const getAdditionalInfo = async () => {
        if(participant?.additionalInformation) {
            const data = participant?.additionalInformation?.replace('[','').replace(']','').split(',')
            const jsonData = data?.map((item) => JSON.parse(item)) ?? []
            setAdditionalInfo(jsonData)
        }
    }

    useEffect(() => {
        getAdditionalInfo()
    },[participant])

  return (
    <Modal show={show}>
        <ModalContainer>
            <Text>ID: {participant?.id}</Text>
            <Text>Nome: {participant?.name}</Text>
            <Text>Sobrenome: {participant?.lastName}</Text>
            <Text>Documento: {participant?.documentNumber}</Text>
            {participant?.state && <Text>Estado: {participant?.state}</Text>}
            {participant?.city && <Text>Cidade: {participant?.city}</Text>}
            {participant?.companyName && <Text>Empresa: {participant?.companyName}</Text>}
            {participant?.companyPosition && <Text>Cargo: {participant?.companyPosition}</Text>}
            {participant?.cellPhoneNumber && <Text>Telefone Celular: {participant?.cellPhoneNumber}</Text>}
            {participant?.commercialPhoneNumber && <Text>Telefone Celular: {participant?.commercialPhoneNumber}</Text>}
            {participant?.email && <Text>E-mail: {participant?.email}</Text>}
            {participant?.linkOrigin && <Text>Origem: {participant?.linkOrigin}</Text>}
            {participant?.additionalInformation && 
                additionalInfo?.map(key => 
                    <Text>{`${Object.keys(key)[0]}: ${Object.values(key)[0]}`}</Text>
                )
            }
            <TButton onClick={onButtonClick}>
                Fechar
            </TButton>
        </ModalContainer>
    </Modal>
  )
}
