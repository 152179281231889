import { Background } from '@/Components/Background';
import ModalLoading from '@/Components/ModalLoading';
import React, { useEffect, useState } from 'react'
import { GetAttractionsContainer, Grow, MenuRow, SmallerColumn, WhiteBoxContainer, StatusButton } from './styled';
import { Header } from '@/Components/Header';
import { FormButton } from '@/Components/FormButton';
import { useNavigate, useParams } from 'react-router-dom';
import { Dropdown, DropdownButton, Form, Table } from 'react-bootstrap';
import { Attraction } from '@/Services/api/models/attractionsModels';
import ApiService from '@/Services/api/ApiService';
import { UsersResponse } from '@/Services/api/models/usersModel';
import { toast } from 'react-toastify';
import { Group, Room } from '@/Services/api/models/ControleDeAcessoModels';
import ControleDeAcessoService, { websocketURL } from '@/Services/controle-de-acesso/ControleDeAcessoService';
import { SignInResponse } from '@/Services/api/models/signInModel';
import { GetParticipantResponse } from '@/Services/api/models/participantsModel';

export default function Salas() {
    const navigate = useNavigate()
    const { eventID } = useParams()
    const [eventId, setEventId] = useState<string>()
    const [userInfo, setUserInfo] = useState<SignInResponse>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [rooms, setRooms] = useState<Room[]>()
    const [roomsSelected, setRoomsSelected] = useState<Room[]>([])
    const [groups, setGroups] = useState<Group[]>()
    const [roomsAvailability, setRoomsAvailability] = useState<any>({})
    const [participants, setParticipants] = useState<GetParticipantResponse[]>()


    const getEventId = async () => {
        const userInfo = await JSON.parse(localStorage.getItem('token'))
        setUserInfo(userInfo)
        if (eventID) {
            setEventId(eventID)
        }
        else {
            const eventId = userInfo?.events?.[0]
            setEventId(eventId)
        }
    }

    const getParticipants = async () => {
        try {
            const response = await ApiService.getAllParticipantsByEvent(Number(eventId))
            setParticipants(response)
        } catch (e: any) {
            console.log(e)
            toast.error('Ocorreu um erro ao carregar os participantes')
        }
    }

    const getEventRooms = async () => {
        setIsLoading(true)
        try{
            const data = await ControleDeAcessoService.getRoomsByEvent(Number(eventId))
            setRooms(data)
        }
        catch (e: any) {
            toast.error(e?.response?.data?.message ?? 'Erro desconhecido')
        }
        finally{
            setIsLoading(false)
        }
    }

    const getEventGroups = async () => {
        setIsLoading(true)
        try{
            const data = await ControleDeAcessoService.getGroupsByEvent(Number(eventId))
            setGroups(data)
        }
        catch (e: any) {
            toast.error(e?.response?.data?.message ?? 'Erro desconhecido')
        }
        finally{
            setIsLoading(false)
        }
    }

    const handleSelectRoom = (room: Room) => {
        if(roomsSelected?.includes(room)) {
            setRoomsSelected(roomsSelected.filter(att => att !== room))
        }
        else {
            setRoomsSelected([...roomsSelected,room])
        }
    }

    const handleDeleteRoom = async (room: Room) => {
        setIsLoading(true)
        try{
            const request = {
                token: userInfo?.token,
                user: userInfo?.user?.id,
                id: room?.id
            }
            const response = await ControleDeAcessoService.deleteRoom(request)
            toast.success('Sala excluída')
            setRoomsSelected(roomsSelected.filter(att => att !== room))
            
        }
        catch (e: any) {
            toast.error(e?.response?.data?.message ?? 'Erro desconhecido')
        }
        finally{
            setIsLoading(false)
            getEventRooms()
        }
    }

    const handleMassAction = async (f: Function) => {
        roomsSelected.map((room) => {
            f(room)
        })
    }

    const handleGenerateList = async (room, group) => {
        setIsLoading(true)
        const promises = participants.map((p) => {
            if(rooms?.find((r) => r.id === room)?.allowed_categories?.includes(p?.additionalCategory?.trim())) {
                return ControleDeAcessoService.registerParticipant({
                    rentpass_participant: p.id,
                    room: room,
                    participant_category: p?.additionalCategory,
                    group: group
                });
            }
            else {
                return
            }
        });
    
        try {
            const responses = await Promise.all(promises);
            toast.success('Lista gerada')
        } catch (error) {
            console.error('Error registering participants:', error);
            toast.error('Erro ao gerar lista')
        }
        finally {
            setIsLoading(false)
        }
    };

    useEffect(() => {
        if(eventId) {
            getEventRooms()
            getEventGroups()
            getParticipants()
        }
    },[eventId])

    useEffect(() => {
        getEventId()
    },[])
        
    useEffect(() => {
        if(eventId){
            const ws = new WebSocket(`${websocketURL}/event/?event=${eventId}`)
            ws.onmessage = (e) => {
                const data = JSON.parse(e.data)
                if(Object.keys(data)?.includes('rooms')) {
                    setRoomsAvailability(data.rooms)
                }
            }
        }
    },[eventId])
    
  return (
    <Background>
            <ModalLoading isActive={isLoading} />
            <GetAttractionsContainer>
                <Header pageTitle='Controle de acesso' />
                <WhiteBoxContainer>
                    <MenuRow>
                        {userInfo?.user?.userGroup === 'ADMIN' && <>
                            <Grow>
                                <FormButton onClick={() => navigate(`/modulos/controle-de-acesso/adicionar/${eventId}`)}>
                                    Novo
                                </FormButton>
                            </Grow>
                            <Grow>
                                <FormButton onClick={() => navigate(`/modulos/controle-de-acesso/grupos/${eventId}`)}>
                                    Grupos
                                </FormButton>
                            </Grow> 
                            {roomsSelected.length > 0 &&
                                <Grow>
                                    <FormButton 
                                        className='bg-danger' 
                                        onClick={() => handleMassAction(handleDeleteRoom)}
                                    >
                                        Excluir Selecionados
                                    </FormButton>
                                </Grow>
                            }
                        </>}
                        <Grow>
                            <FormButton onClick={() => navigate(`/modulos/controle-de-acesso/participantes/${eventId}`)}>
                                Lista de participantes
                            </FormButton>
                        </Grow> 
                    </MenuRow>
                    <div className='body'></div>
                    <div className='tabela'>
                        {rooms && (
                            <Table hover>
                                <thead>
                                    <tr>
                                        {userInfo?.user?.userGroup === 'ADMIN' && <>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </>}
                                        <th>Nome</th>
                                        <th>Grupo</th>
                                        <th>Limite de Participantes</th>
                                        <th>Lugares Disponíveis</th>
                                        <th>Categorias Permitidas</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {rooms.map((room) => 
                                        <tr onDoubleClick={() => navigate(`/modulos/controle-de-acesso/consultar/${eventId}/${room.id}`)}>
                                            {userInfo?.user?.userGroup === 'ADMIN' && <>
                                                <SmallerColumn>
                                                    <Form.Check
                                                        type={'checkbox'}
                                                        onChange={() => handleSelectRoom(room)}
                                                        checked={roomsSelected?.includes(room)}
                                                    />
                                                </SmallerColumn>
                                                <SmallerColumn>
                                                    <StatusButton onClick={() => navigate(`/modulos/controle-de-acesso/editar/${eventID}/${room.id}`)}>
                                                        Editar
                                                    </StatusButton>
                                                </SmallerColumn>
                                                <SmallerColumn>
                                                    <FormButton onClick={() => handleGenerateList(room?.id, room?.group)}>
                                                        Gerar Lista
                                                    </FormButton>
                                                </SmallerColumn>
                                            </>}
                                            <td>
                                                {room.room_name}
                                            </td>
                                            <td>
                                                {groups?.find(group => group.id === room.group)?.group_name}
                                            </td>
                                            <td>
                                                {room.room_availability}
                                            </td>
                                            <td>
                                                {roomsAvailability[room.id]}
                                            </td>
                                            <td>
                                                {room.allowed_categories ?? 'Todas'}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        )}
                    </div>
                </WhiteBoxContainer>
            </GetAttractionsContainer>
        </Background>
  )
}
