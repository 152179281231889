export enum IUserGroup {
    'ADMIN' = 'ADMINISTRADOR',
    'RECEPTION' = 'RECEPÇÃO',
    'SELF_SERVICE' = 'AUTO-ATENDIMENTO',
    'PARTNER_ADMIN' = 'PARTNER ADMIN',
    'ATTRACTION' = 'CAPTURE CONTROL - CLIENTE',
    'ATTRACTION_RECEPTION' = 'CAPTURE CONTROL - RECEPÇÃO',
    'CLIENT' = 'CLIENTE'
}

export interface UsersResponse {
    id: number;
    name: string;
    events?: number[];
    active: boolean;
    email: string;
    password: string;
    userGroup: keyof typeof IUserGroup;
    additionalCategory?: string;
}

export interface CreateUserRequest {
    id?: number;
    active: boolean;
    events?: number[];
    email: string;
    name: string;
    password: string;
    userGroup: keyof typeof IUserGroup;
    additionalCategory?: string;
}
