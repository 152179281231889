export const formatDateToString = (dateInString: string) => {
    const a = dateInString.split('-');
    return `${a[2]}/${a[1]}/${a[0]}`;
};

export function formatDjangoDate (date: string | null) {
    if (date) {
        const year = date.split('-')[0]
        const month = date.split('-')[1]
        const day = date.split('-')[2]?.slice(0,2)
        const time = date.split('T')[1]?.slice(0,8)
        return `${day}/${month}/${year} às ${time}`
    }
    else {
        return ''
    }
}